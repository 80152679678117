<template>
  <div class="homeBox">
    <van-pull-refresh v-model="refresh" @refresh="onRefresh">
      <!-- 搜索 -->
      <div class="searchBox" @click="searchProduct">
        <div class="searchInfo">
          <figure class="searchLogo">
            <img
              class="searchLogoIcon"
              src="~assets/img/client/icon_home_search@2x.png"
            />
          </figure>
          <div class="searchText">{{ $t.meta.search }}</div>
        </div>
      </div>

      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t.noMoreData"
        @load="onLoad"
      >
        <home-swiper :images="images"></home-swiper>
        <!-- 轮播图已接数据完毕  无点击事件-->

        <profitable :ListViewOddsProduct="ListViewOddsProduct"></profitable>
        <!-- profitable已接数据  部分数据没有 比如打折,Usd。无点击事件-->

        <div class="promo">
          <div @click="move">{{ $t.meta.allPromotions }}</div>
        </div>

        <div class="message" @click="getNewCenters">
          <div class="message-div" @click.stop="getAllProduct">
            <img class="message-div-more" :src="icon_1img" alt />
          </div>
          <div class="message-center">
            {{ $t.meta.messageText(messageAmount) }}
          </div>
          <div class="message-check" @click="goMessage">
            {{ $t.meta.checkNow }}
          </div>
        </div>
        <!-- 无数据 无点击事件 -->

        <personal :ListViewOddsClass="ListViewOddsClass"></personal>
        <!-- personal已链接数据 无detail图/文 //无点击事件 -->

        <van-cheen :ListProduct="ListProduct"></van-cheen>
        <!-- van-cheen已链接数据  无点击事件/往下拉 -->
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem, Sticky, List } from "vant";
Vue.use(Swipe).use(SwipeItem).use(List).use(Sticky);

import HomeSwiper from "./components/HomeSwiper";
import Profitable from "./components/Profitable";
import Personal from "./components/Personal";
import VanCheen from "./components/VanCheen";
import { PullRefresh } from "vant";

export default {
  name: "Home",
  components: {
    HomeSwiper,
    Profitable,
    Personal,
    VanCheen,
    "van-pull-refresh": PullRefresh,
  },
  data() {
    return {
      refresh: false,
      loading: true,
      images: [],
      ListViewOddsProduct: [],
      ListProduct: [],
      finished: false,
      pageIndex: 1,
      ListViewOddsClass: [],
      icon_1img: require("../../../assets/img/home/icon_1.png"),
      icon_2img: require("../../../assets/img/home/icon_2.png"),
      icon_3img: require("../../../assets/img/home/icon_3.png"),
      messageAmount: 0,
    };
  },
  mounted() {
    this.loadIndex();
  },
  beforeCreate() {},
  methods: {
    searchProduct() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "searchHistory",
        name: "search",
        query: [],
      });
    },
    move() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "search",
        name: "search",
        query: [
          {
            name: "title",
            value: "ALL PROMOTIONS",
          },
        ],
      });
    },
    //跳转消息中心(app原生页面)
    getNewCenters() {
      //消息穿透
      this.$commonEnv.commonAction(this.$commonEnv.actionType.newscenter);
    },
    goMessage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "messages",
        name: "Messages",
        query: [],
      });
    },
    onRefresh() {
      this.refresh = true;
      this.loadIndex(true)
        .then(() => {
          this.refresh = false;
        })
        .catch(() => {
          this.refresh = false;
        });
    },
    // 获取首页数据
    loadIndex(hideLoading = false) {
      return new Promise((resolve, reject) => {
        if (!hideLoading) {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
        }
        let param = {};
        this.$api.index
          .loadNewIndex(param)
          .then((res) => {
            const data = res.data;
            this.images = data.ListViewAdverts;
            this.ListViewOddsProduct = data.ListViewOddsProduct;
            this.ListProduct = data.ListProduct;
            this.ListViewOddsClass = data.ListViewOddsClass;
            this.messageAmount = data.MessageAmount;
            if (
              data.ListProduct === null ||
              data.ListProduct === "" ||
              data.ListProduct.length === 0
            ) {
              this.isPlaceholder = true;
            } else {
              this.isPlaceholder = false;
            }
            this.loading = false;
            if (!hideLoading) {
              this.$commonEnv.commonAction(
                this.$commonEnv.actionType.hideLoading
              );
            }
            resolve();
          })
          .catch((error) => {
            this.loading = false;
            if (!hideLoading) {
              this.$commonEnv.commonAction(
                this.$commonEnv.actionType.hideLoading
              );
            }
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showDialog,
              {
                msg: error.message,
                status: error.status,
              }
            );
            reject();
          });
      });
    },
    //加载更多
    onLoad() {
      let param = {};
      if (this.ListProduct !== 1) {
        param = {
          pageIndex: this.pageIndex + 1,
        };
      }
      this.$api.index
        .loadClassProductByPage(param)
        .then((res) => {
          const data = res.data;
          if (data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.loading = false;
            this.ListProduct = this.ListProduct.concat(res.data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    //获取更多商品
    getAllProduct() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "allProducts",
        name: "AllProducts",
        query: [],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.homeBox {
  height: 100%;
  .van-pull-refresh {
    overflow: unset;
  }
  .searchBox {
    z-index: 10;
    width: 100%;
    padding: 3% 0%;
    background: linear-gradient(left, #71d283, #01aaa3);
    .searchInfo {
      width: 90%;
      margin: 0 auto;
      padding: 0.3rem 0.5rem;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 10px;
      background-color: #ffffff;
      position: relative;
      .searchText {
        display: inline-block;
        margin-left: 30px;
        font-size: 14px;
        background-color: #ffffff;
        color: #a7a7a7;
        line-height: 25px;
      }
      .searchLogo {
        margin: 0;
        padding: 0;
        position: absolute;
        justify-content: center;
        -webkit-justify-content: center;
        left: 7px;
        top: 7px;
        @include publicFlex;
        .searchLogoIcon {
          width: 21px;
          height: 21px;
          object-fit: cover;
          -webkit-object-fit: cover;
          // background-color: #434343;
        }
      }
    }
  }
}

.promo {
  text-align: right;
  margin: 15px 20px 0 0;
  font-size: 14px;
  color: #3fc21b;
}
.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-display: flex;
  -webkit-justify-content: space-between;
  -webkit-align-items: center;
  width: 95%;
  margin: 10px auto;
  background: #fff;
  box-shadow: 0px 3px 3px 3px #dadada;
  font-size: 14px;
  padding: 10px;
  border-radius: 20px;
  box-sizing: border-box;

  img {
    width: 25px;
    height: 25px;
  }

  &-div {
    margin-left: 5px;
    margin-right: 10px;
    line-height: 50px;
    display: flex;
    align-content: center;
    justify-content: center;
    &-more {
      width: 35px !important;
      height: 35px !important;
    }
  }
  &-center {
    color: #585a57;
    flex: 1;
    // font-weight: bold;
    span {
      color: #ed6926;
    }
  }
  &-check {
    color: #3fc21b;
    margin-right: 10px;
  }
}
</style>
