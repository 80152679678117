<template>
  <div class="middle">
    <div
      class="personal"
      v-for="(item, index) in ListViewOddsClass"
      v-bind:key="index"
    >
      <!-- <div class="title" @click="search"> -->
      <div class="title" @click="gosearch(item.ClassId, item.ClassName)">
        <div class="title-left">{{ item.ClassName }}</div>
        <div class="title-right">{{ $t.meta.more }}</div>
      </div>
      <div
        class="personal-content"
        @click="gosearch(item.ClassId, item.ClassName)"
      >
        <div class="personal-content-left">
          <img :src="item.ImgUrl" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Personal",
  props: ["ListViewOddsClass"],
  data() {
    return {};
  },
  methods: {
    search() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "search",
        name: "search",
        query: [
          {
            name: "title",
            value: "SHOP MORE",
          },
        ],
      });
    },
    // 跳转详情页
    gosearch(SreachValues, ClassName) {
      console.log();
      if (SreachValues.toString() === "-999") {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "allProducts",
          name: "AllProducts",
          query: [],
        });
        return false;
      }
      console.log(SreachValues);
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "searchClass",
        name: "SearchClass",
        query: [
          {
            name: "ClassName",
            value: ClassName,
          },
          {
            name: "ClassId",
            value: SreachValues,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.middle {
  //background-color: #efefef;
  margin-top: 30px;
  margin-bottom: 30px;
}
.profitable {
  // margin-top: 40px;
  background: #efefef;
  // box-shadow: rgb(156, 155, 155) 0px 0px 20px;
  border-radius: 20px;
}
.title {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 20px;
  box-sizing: border-box;
  // padding: 15px 20px ;
  align-items: center;
  &-left {
    color: #000;
    font-weight: bold;
  }
  &-right {
    color: #3fc21b;
    font-size: 14px;
  }
}
.personal {
  // box-shadow: rgb(189, 188, 188) 0px 0px 20px;
  margin-top: 20px;
  //background: #efefef;
  // padding: 50px 20px 50px 20px;
  // padding: 50px 0;
  &-content {
    margin-top: 10px;
    &-left {
      // width: 35%;
      // height: 400px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.lady-content-right {
  .right-item-dec {
    background: rgb(241, 89, 66);
  }
}
</style>
