<template>
  <div class="bannerList" v-if="images && images.length > 0">
    <van-swipe class="my-swipe" :autoplay="4000" indicator-color="#03AC0E">
      <van-swipe-item v-for="(item, index) in images" :key="index">
        <img
          @click="getProductDetails(item.LinkStu, item)"
          :src="item.ImgUrl && imgUrlFilter(item.ImgUrl)"
          class="swipeItem"
        />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
Vue.use(Swipe).use(SwipeItem);

export default {
  name: "HomeSwiper",
  props: ["images"],
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    getProductDetails(ProductNumber, item) {
      const linkUrl = item.linkUrl;
      if (linkUrl == "-1") {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: true,
          path: "details",
          name: "details",
          query: [
            {
              name: "ProductNumber",
              value: ProductNumber,
            },
          ],
        });
      } else {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: true,
          path: "timeActivities",
          name: "TimeActivities",
          query: [
            {
              name: "id",
              value: item.Id,
            },
          ],
        });
      }
      //    this.$router.push({
      //        path:'/details?ProductNumber='+data.LinkStu
      //    })
      // },
      //  getProductDetails(ProductNumber, item) {
      // const linkUrl = item.LinkUrl;
      // //判断是否特殊活动
      // if (linkUrl != "-1") {
      //   this.$router.push({
      //     isParams: true,
      //     path: "/details",
      //     name: "details",
      //     query: [
      //       {
      //         name: "ProductNumber",
      //         value: item.ProductNumber
      //       }
      //     ]
      //   });
      // } else {
      //   this.$router.push({
      //     isParams: true,
      //     path: "/timeActivities",
      //     name: "TimeActivities",
      //     query: [
      //       {
      //         name: "id",
      //         value: item.Id
      //       }
      //     ]
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-swipe__indicators {
  bottom: 15px;
}
::v-deep .van-swipe__indicator {
  width: 6px;
  height: 6px;
}
.bannerList {
  .my-swipe .van-swipe-item {
    width: 100%;
    .swipeItem {
      width: 100%;
      height: 55.47vw;
      max-height: 580px;
      object-fit: cover;
    }
  }
}
</style>
