<template>
  <div class="profitable">
    <div class="title" @click="goSearch">
      <div class="title-left">{{ $t.meta.Profitable }}</div>
      <div class="title-right">{{ $t.meta.more }}</div>
    </div>
    <div class="profitable-content">
      <div
        class="profitable-content-item"
        v-for="(item, index) in ListViewOddsProduct"
        v-bind:key="index"
        @click="godetail(item.ProductNumber)"
      >
        <div class="profitable-content-item-img">
          <img :src="item.ImgUrl" alt />
        </div>
        <!-- <div class="profitable-content-item-price">{{ item.Sales }} Sold</div> -->
        <div class="profitable-content-item-usd">Usd {{ item.Price }}</div>
        <div class="profitable-content-promotion">-{{ item.PriceRate }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profitable",
  props: ["ListViewOddsProduct"],
  data() {
    return {};
  },
  methods: {
    godetail(ProductNumber) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
        ],
      });
    },
    goSearch() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "search",
        name: "search",
        query: [
          {
            name: "title",
            value: "SHOR MORE",
          },
          {
            name: "IsCouponProduct",
            value: 1,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profitable {
  width: 95%;
  margin: 10px auto;
  background: #fff;
  box-shadow: 0px 2px 5px 5px #dadada;
  border-radius: 20px;
}
.title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 15px 10px;
  align-items: center;
  &-left {
    color: #000;
    font-weight: bold;
  }
  &-right {
    color: #3fc21b;
    font-size: 14px;
  }
}
.profitable-content {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  &-promotion {
    position: absolute;
    top: 3px;
    right: 0px;
    font-size: 10px;
    padding: 3px 3px 3px 10px;
    width: 40px;
    color: #fff;
    background-image: url("~assets/img/home/discount.png");
    background-position: center;
    background-size: 50px;
    background-repeat: no-repeat;
  }
  &-item {
    position: relative;
    flex: 1;
    padding: 5px;
    text-align: center;
    &-img img {
      width: 100%;
      height: 26.7vw;
      border-radius: 10px;
      object-fit: cover;
    }
    &-usd {
      font-size: 14px;
      color: #ed6926;
      margin-top: 10px;
      margin-bottom: 5px;
      font-weight: bold;
    }
    &-price {
      display: inline-block;
      background: #03ac0e;
      color: #fff;
      font-weight: bold;
      font-style: italic;
      border-radius: 5px;
      padding: 3px 7px;
      font-size: 10px;
    }
  }
}
</style>
